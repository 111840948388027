import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
import { Helmet } from 'react-helmet'
import Layout from '../../components/Layout'
import ProductsList from '../../components/ProductList'

const Efteling = () => {
  const token = uuidv4()
  const apiUrl = `https://api.kameleon-media.be/feeds/ppv/efteling.json?q=${token}`

  const [products, setProducts] = useState([])
  let loading = true

  const getData = async () => {
    await axios
      .get(apiUrl)
      .then(response => {
        loading = false
        setProducts(response.data.products)
      })
      .catch(error => {
        console.error('error: ', error)
      })
  }
  useEffect(() => getData(), [])

  return (
    <Layout>
      <Helmet>
        <title>{`Efteling verblijven aanbod | Pretparkvoordeel`}</title>
        <link
          rel="canonical"
          href="https://pretparkvoordeel.be/aanbod/efteling/"
        />
        <meta name="description" content="Efteling verblijven aanbod" />
        <meta
          name="og:title"
          content={`Efteling verblijven aanbod | Pretparkvoordeel`}
        />
        <meta name="og:description" content="Efteling verblijven aanbod" />
      </Helmet>
      <section className="section-post">
        <div className="container">
          {products && <ProductsList brand="Efteling" products={products} />}
        </div>
      </section>
    </Layout>
  )
}

export default Efteling
